import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import "./Support.css";
import { useTranslation } from "react-i18next";

export default function Support() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, []);

  const questionsData = {
    header: { "Getting Started": "getting-started" },
    questions: [
      {
        "What you need to get started": "What-you-need-to-get-started",
      },
      {
        "Investments you can make on Robinhood":
          "Investments-you-can-make-on-Robinhood",
      },
      {
        "Open my account": "Open-my-account",
      },
    ],
  };

  return (
    <div className="support-screen">
      <div className="support-container">
        <h2 className="support-title">
          {t("support.h")} <br /> {t("support.hh")}
        </h2>
        <div className="support-info-sections">
          <div className="support-info-section">
            <div className="support-section-header">{t("support.gs")}</div>
            <br />
            <Link
              className="support-section-question"
              to="https://wa.link/2a2nwl"
            >
              {t("support.wgs")}
            </Link>
            <br /> <br />
            <Link
              className="support-section-question"
              to="https://wa.link/k4o7nh"
            >
              {t("support.afi")}
            </Link>
            <br /> <br />
            <Link
              className="support-section-question"
              to="https://wa.link/g5w8bg"
            >
              {t("support.oa")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
