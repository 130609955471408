import React, { useEffect } from "react";
import investHeader from "../../img/invest-header.png";
import "./Invest.css";
import PrimaryBtn from "../../components/Btns/PrimaryBtn";
import { AiFillInfoCircle } from "react-icons/ai";
import stocksPhone from "../../img/stocks-phone.png";
import onetime_crop from "../../img/onetime_crop.png";
import lifetime_crop from "../../img/lifetime_crop.png";
import stable_investment1 from "../../img/stable_investment1.png";
import { useTranslation } from "react-i18next";

const InvestingCard = ({ title, description, tooltip, img }) => {
  return (
    <div className="section-3-item">
      <div>
        <div class="text-container">
          <div class="invest-info-title">
            <span className="yellow-bg">{title[0]}</span>
            <br />
            {title[1]}
          </div>
          <div class="invest-info-text">{description}</div>
          <div className="conditions-apply">
            <AiFillInfoCircle size={20} colorInterpolation="white" />
            {tooltip}
          </div>
        </div>
        <div className="invest-item-img">
          <img src={img} alt="Bitcoin Graph" />
        </div>
      </div>
    </div>
  );
};

export default function Invest({ title }) {
  useEffect(() => {
    document.title = title;
  }, []);

  const { t, i18n } = useTranslation();
  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, []);

  const data = [
    {
      title: [`${t("agricultural_funds.o")}`, `${t("agricultural_funds.o2")}`],
      description: `${t("agricultural_funds.od")}`,
      tooltip: `${t("agricultural_funds.od2")}`,
      img: onetime_crop,
    },
    {
      title: [`${t("agricultural_funds.l")}`, `${t("agricultural_funds.l2")}`],
      description: `${t("agricultural_funds.ld")}`,
      tooltip: `${t("agricultural_funds.ld2")}`,
      img: lifetime_crop,
    },
    //   {
    //     title: ["Margin", "Increase your buying power"],
    //     description:
    //       "Need more leverage to purchase the stocks and ETFs you’ve been eyeing? With Margin, you can preserve your extra cash by borrowing some from us.",
    //     tooltip: "Risk of Margin Disclosure",
    //   },
    {
      title: [`${t("agricultural_funds.s")}`, `${t("agricultural_funds.i")}`],
      description: `${t("agricultural_funds.sid")}`,
      tooltip: `${t("agricultural_funds.sid2")}`,
      img: stable_investment1,
    },
  ];

  return (
    <div className="invest-section">
      <div className="invest-header">
        <img src={investHeader} alt="Investment Graph" />
        <div class="invest-info">
          <h1 class="invest-info-title">{t("agricultural_funds.t")}</h1>
          <div class="invest-info-text">
            <p>{t("agricultural_funds.td")}</p>
          </div>
          <div class="conditions-apply"></div>
          <div className="get-started-button">
            <PrimaryBtn title="Get started" />
          </div>
          <div class="warning">
            <p class="warning">
              {t("agricultural_funds.p")} &amp; {t("agricultural_funds.p2")}
              <span> {t("agricultural_funds.p3")} </span>
              {t("agricultural_funds.p4")}
            </p>
          </div>
        </div>
      </div>
      <div className="invest-section-2">
        <div class="section-2-container">
          <div class="text-container">
            <div class="invest-info-title">
              <span className="yellow-bg">{t("agricultural_funds.p")}</span>
              <br />
              {t("agricultural_funds.pd2")}
            </div>
            <div class="invest-info-text">{t("agricultural_funds.ff")}</div>
            <div className="conditions-apply">
              <AiFillInfoCircle size={20} colorInterpolation="white" />
              {t("agricultural_funds.ca")}
            </div>
          </div>
          <div class="stocks-phone">
            <img src={stocksPhone} alt="" />
          </div>
        </div>
      </div>
      <div className="invest-section-3">
        {data.map((item, index) => (
          <InvestingCard
            title={item.title}
            description={item.description}
            tooltip={item.tooltip}
            img={item.img}
            key={index}
          />
        ))}
      </div>
    </div>
  );
}
