
              import React, { useEffect } from "react";
          import "../Article.css";

          export default function NQA({ title }) {
            useEffect(() => {
              document.title = title;
            }, []);

            return (
              <div className="article-screen">
                <div className="article-container">
                  <span className="article-secondary-text">Updated March 14, 2024 </span>
                  <h1 className="article-h1">What is the NASDAQ</h1>
                  <h2 className="article-h2">🤔 Understanding What is the NASDAQ</h2>
                  <p className="article-p1">
                  The NASDAQ, or the National Association of Securities Dealers Automated Quotations, is a stock exchange based in the United States. It is one of the largest stock exchanges in the world, second only to the New York Stock Exchange. The NASDAQ primarily focuses on technology companies, although it also lists companies from other sectors such as biotechnology, healthcare, and finance.

The NASDAQ was established in 1971 and was the world's first electronic stock market. Unlike traditional stock exchanges, which use floor traders to facilitate transactions, the NASDAQ relies on a computerized system to match buyers and sellers. This automated trading system allows for faster and more efficient transactions, as well as increased transparency in the market.

One of the key features of the NASDAQ is its use of market makers, which are firms that buy and sell securities on behalf of investors. These market makers help to ensure liquidity in the market, making it easier for investors to buy and sell securities. The NASDAQ also offers a variety of indices, including the NASDAQ Composite Index and the NASDAQ-100 Index, which track the performance of the stocks listed on the exchange.

Overall, the NASDAQ is an important player in the global financial markets, providing a platform for companies to raise capital and for investors to buy and sell securities. Its focus on technology and innovation has made it particularly attractive to companies in the tech sector, resulting in the listing of many high-profile companies such as Apple, Microsoft, and Amazon.
                  </p>
                  <div className="article-p-example article-p1">
                    <span className="article-p-example-title">Example</span> <br />
                    The NASDAQ is a leading stock exchange in the US, second only to the NYSE. Established in 1971, it was the world's first electronic market. The NASDAQ primarily focuses on technology companies, but also lists firms in biotech, healthcare, and finance. It operates using a computerized system for trading, providing faster transactions and increased transparency. Market makers facilitate the buying and selling of securities and indices like the NASDAQ Composite track performance. With its emphasis on technology and innovation, the NASDAQ is a crucial platform for companies to raise capital and investors to trade securities, attracting top tech giants like Apple, Microsoft, and Amazon.

                  </div>
                  <h2 className="article-h2">Takeaway</h2>
                  <div className="article-blockquote">
      Invest in technology companies on the NASDAQ for potential growth and innovation in your portfolio.
                  </div>
                  <p className="article-p1">
                  The NASDAQ is a leading US stock exchange, focusing on technology companies while also listing firms in other sectors. Established in 1971, it operates as the world's first electronic market, providing efficient trading and liquidity through market makers. With indices like the NASDAQ Composite, it is a key platform for companies to raise capital and investors to trade securities, attracting top tech giants like Apple and Microsoft.
                  </p>
                </div>
              </div>
            );
          }

              