import React, { useEffect } from "react";
import HeroVideo from "../../videos/hero-video.mp4";
import { Link } from "react-router-dom";

import "./Hero.css";
import { useTranslation } from "react-i18next";

export default function Hero() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, []);
  return (
    <div className="hero">
      <video
        width="100%"
        autoPlay
        controlslist="nodownload nofullscreen noremoteplayback"
        muted
        playsinline
        preload="auto"
      >
        <source src={HeroVideo} />
      </video>
      <div className="hero-video-title">{t("landing.hero_per")}</div>
      <div className="learn-more">
        <Link className="learn-more-item" to="https://wa.link/l9r1m8">
          {t("landing.hero_btn")}
        </Link>
      </div>
    </div>
  );
}
