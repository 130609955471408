import React from "react";
import "./Footer.css";
import {
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiFillLinkedin,
  AiFillYoutube,
} from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";

export default function Footer() {
  return (
    <footer>
      <div className="footer-section-top">
        <b>Customer Relationship Summary</b>
      </div>
      <div className="footer-section-top">
        <div className="footer-social-icons follow-us">
          <span>Follow us on</span>
          <a
            href="https://www.instagram.com/americafoundation/"
            target="_blank" // Opens link in a new tab
            rel="noopener noreferrer" // Recommended for security
          >
            <AiOutlineInstagram size={26} />
          </a>
          <a
            href="https://twitter.com/AmericaFDN"
            target="_blank" // Opens link in a new tab
            rel="noopener noreferrer" // Recommended for security
          >
            <AiOutlineTwitter size={26} />
          </a>
          <a
            href="https://www.linkedin.com/company/america-foundation/"
            target="_blank" // Opens link in a new tab
            rel="noopener noreferrer" // Recommended for security
          >
            <AiFillLinkedin size={26} />
          </a>
          {/* <FaTiktok size={26} /> */}
          {/* <AiFillYoutube size={26} /> */}
        </div>
      </div>
    </footer>
  );
}
