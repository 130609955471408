import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Library.css";

const importAll = (context) => {
  let articles = [];
  context.keys().forEach((key) => {
    articles.push(context(key).default);
  });
  return articles;
};

const articleContext = require.context("../Articles", false, /\.jsx$/);
const articles = importAll(articleContext);

export default function Library({ title }) {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div>
      <div className="library-screen-section">
        <div className="library-screen-title">Explore the library</div>
        <div className="library-screen-tagline">
          Investing basics—in plain English.
        </div>
        <div className="library-screen-items">
          {articles.map((Article, index) => (
            <Article key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}
