import React from 'react'

function Account_Recurring() {
  return (
    <div>
      <h1>Recurring</h1>
    </div>
  )
}

export default Account_Recurring
