import React, { useEffect } from "react";
import TextHero from "../../components/TextHero/TextHero";
import PrimaryBtn from "../../components/Btns/PrimaryBtn";
import "./Crypto.css";
import hero1 from "../../img/heros/hero-crypto.png";
import hero2 from "../../img/heros/hero-crypto-2.png";
import fastIcon from "../../img/icons/fast-icon.png";
import dollarIcon from "../../img/icons/dollar-icon.png";
import WorldMoneyIcon from "../../img/icons/world-money-icon.png";
import { useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router-dom";

export default function Crypto({ title }) {
  useEffect(() => {
    document.title = title;
  }, []);

  const { t, i18n } = useTranslation();
  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, []);

  return (
    <>
      <div className="crypto-section">
        <div className="crypto-hero crypto-hero-primary">
          <div className="crypto-hero-container">
            <div className="crypto-hero-text">
              <div className="crypto-hero-title">{t("banking_funds.t")}</div>
              <div className="crypto-hero-subtitle">
                {t("banking_funds.td")}
              </div>
              <div className="crypto-hero-btn">
                <Link className="primary-btn" to="https://wa.link/l9r1m8">
                  {t("banking_funds.gs")}
                </Link>
              </div>
            </div>
            <div className="crypto-hero-img">
              <img src={hero1} alt="Bitcoin wallet phone flat image" />
            </div>
          </div>
        </div>
        <div className="crypto-hero-second">
          <div className="crypto-hero-container ">
            <div className="crypto-second-hero-img">
              <img src={hero2} alt="Bitcoin wallet phone flat image" />
            </div>
            <div className="crypto-hero-second-items">
              <div className="crypto-hero-item">
                <img src={fastIcon} className="crypto-hero-second-icon" />
                <div className="crypto-hero-item-text">
                  <b>{t("banking_funds.su")}</b>
                  {t("banking_funds.sud")}
                </div>
              </div>
              <div className="crypto-hero-item">
                <img src={dollarIcon} className="crypto-hero-second-icon" />
                <div className="crypto-hero-item-text">
                  <b>{t("banking_funds.ss")}</b>
                  {t("banking_funds.ssd")}
                </div>
              </div>
              <div className="crypto-hero-item">
                <img src={WorldMoneyIcon} className="crypto-hero-second-icon" />
                <div className="crypto-hero-item-text">
                  <b>{t("banking_funds.ff")}</b>
                  {t("banking_funds.ffd")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
