import React from 'react'

function Account_Investing() {
  return (
    <div>
      <h1>hi nigga</h1>
    </div>
  )
}

export default Account_Investing
