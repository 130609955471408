import React, { useEffect } from "react";
import wid from "../../img/articles/WiD.svg";
import Investment1 from "../../img/articles/investment1.svg";
import "./Article.css";

export default function WiD({ title }) {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div className="article-screen">
      <div className="article-container">
        <span className="article-secondary-text">Updated April 26, 2023</span>
        <h1 className="article-h1">What is Diversification?</h1>
        <div className="article-img">
          <img src={wid} alt="" />
        </div>
        <h2 className="article-h2">🤔 Understanding Diversification</h2>
        <p className="article-p1">
          Financial diversification is a strategy to reduce risk by spreading
          investments across different asset classes, industries, or geographic
          regions. By not putting all eggs in one basket, investors aim to
          minimize the impact of poor performance in a single investment on
          their overall portfolio. This approach allows potential gains from
          well-performing investments to offset losses from underperforming
          ones, creating a more stable and balanced investment profile.
        </p>
        <div className="article-p-example article-p1">
          <span className="article-p-example-title">Example</span> <br />
          Meet James, a 29-year-old professional with a keen interest in
          building a solid financial future. He recently came into $100,000, and
          he's determined to invest it wisely. James understands the importance
          of diversification to manage risk, given his age and financial goals.
          <br />
          James decides to divide his $100,000 into different investment
          avenues:
          <h4>Agricultural Funds:</h4> James allocates $30,000 to agricultural
          funds. He believes in the long-term potential of sustainable
          agriculture and expects steady growth in this sector.
          <h4>Banking Funds:</h4> To balance his portfolio, James invests
          $25,000 in banking funds. He considers this a relatively stable option
          that can provide consistent returns over time.
          <h4>Cash:</h4> Recognizing the importance of liquidity, James keeps
          $15,000 in cash. This provides him with a financial cushion and the
          flexibility to seize opportunities as they arise.
          <h4>Stocks:</h4> James is willing to take some calculated risks, so he
          invests $6,000 in individual stocks of high-growth companies operating
          in emerging industries. He's well aware that stock investments can be
          volatile, but he's drawn to their potential for substantial returns.
          <br />
          <br />
          James believes that a diversified approach will help him manage risk
          while pursuing growth. He plans to monitor his investments regularly
          and make adjustments as needed, aligning his portfolio with his
          evolving financial goals.
        </div>
        <h2 className="article-h2">Takeaway</h2>
        {/* <div className="article-blockquote">
          Shares give you the ability to Invest in lots of Ventures without a
          Large Capital
        </div> */}
        <p className="article-p1">
          In conclusion, Financial diversification is the practice of spreading
          investments across various assets to minimize risk. By not relying on
          a single investment, individuals can reduce the impact of losses and
          create a more stable portfolio.
        </p>
        <div className="article-img">
          {/* <img src={Investment} alt="" /> */}
        </div>
      </div>
    </div>
  );
}
