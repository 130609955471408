import { onAuthStateChanged, signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  onSnapshot,
  getDocs,
  getDoc,
  query,
  where,
  updateDoc,
  orderBy,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { useAuth } from "../AuthContext";

const AuthDetails = () => {
  const [authUser, setAuthUser] = useState(null);
  const [firstname, setfirstname] = useState(null);
  const { currentUser } = useAuth();
  const uid = currentUser.uid;

  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });

    return () => {
      listen();
    };
  }, []);

  onSnapshot(doc(db, "users", uid), orderBy("date", "desc"), (doc) => {
    const current = doc.data().firstname;
    if (JSON.stringify(firstname) !== JSON.stringify(current)) {
      setfirstname(current);
    }
  });

  const userSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("sign out successful");
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      {authUser ? (
        <>
          {/* <p>{`Welcome ${authUser.email}`}</p> */}
          <button onClick={userSignOut}>Not {firstname}?</button>
        </>
      ) : (
        <p>Signed Out</p>
      )}
    </div>
  );
};

export default AuthDetails;
