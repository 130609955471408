import React from "react";
import { Link } from "react-router-dom";

import "./P404.css";

export default function P404() {
  return (
    <div className="support-screen">
      <div className="support-container">
        <h2 className="support-title">
          <br /> Looks like you are trying something Funky?
        </h2>
        <br />
        <h3 className="support_description">
          try selecting something on the header instead
        </h3>
        <div className="support-info-sections"></div>
      </div>
    </div>
  );
}
