import React, { useEffect } from "react";
import fti from "../../img/articles/fti.svg";
import Investment1 from "../../img/articles/investment1.svg";
import "./Article.css";

export default function USAVisa({ title }) {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div className="article-screen">
      <div className="article-container">
        <span className="article-secondary-text">Updated April 26, 2022</span>
        <h1 className="article-h1">Visa a Estados Unidos de America</h1>
        <div className="article-img">
          <img src={fti} />
        </div>
        <h2 className="article-h2">
          🤔 Quieres ser Patrocinado a Estados Unidos?
        </h2>
        <p className="article-p1">
          "¡Patrocinamos gratuitamente a nuestros inversionistas para expandir
          sus negocios y oportunidades en Estados Unidos! ¡Únete a nosotros a
          los miles de inversionistas ya patrocinados con Visa Americana y haz
          crece tu visión empresarial hoy mismo!"
        </p>
        <div className="article-p-example article-p1">
          <span className="article-p-example-title">Ejemplo</span> <br />
          Normalmente, nuestros inversionistas reciben una visa de 10 años, con
          una estadía máxima de 6 meses por año en los Estados Unidos, y en
          varios casos se puede extender. Es decir cada año ellos pueden estar
          un maximo de 6 meses, pero se recomienda pedir 6 messes por motivo de
          negocios al sellar tu pasaporte en el aeropuerto de estados unidos, de
          otra manera les tocaria salir y volver a entrar. dependiedo de el
          tiempo administrado. Nuestros Agentes les pueden ayudar despues de
          verificar cualquiera de sus inversiones.
        </div>
        <h2 className="article-h2">Obtiene</h2>
        <div className="article-blockquote">B-1 Visa de Negocios</div>
        <p className="article-p1">
          La visa B-1 de negocios es para visitantes temporales que desean
          ingresar a Estados Unidos con el propósito de llevar a cabo
          actividades comerciales legítimas, como asistir a reuniones,
          conferencias, negociaciones de contratos, investigaciones de mercado o
          capacitaciones cortas. No permite el empleo remunerado ni la venta de
          bienes o servicios dentro del país. La duración de la estadía está
          determinada por la naturaleza de la actividad comercial, generalmente
          hasta seis meses, con la posibilidad de extenderla en ciertos casos.
        </p>
        <div className="article-img">
          {/* <img src={Investment} alt="" /> */}
        </div>
      </div>
    </div>
  );
}
