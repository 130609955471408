import React, { useEffect, useState } from "react";
import "./Account_Header.css";
import { Link } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import {
  collection,
  doc,
  onSnapshot,
  getDocs,
  getDoc,
  query,
  where,
  updateDoc,
  orderBy,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { useAuth } from "../../AuthContext";

function Account_Header() {
  const [authUser, setAuthUser] = useState(null);
  const [firstname, setfirstname] = useState(null);
  const [lastname, setlastname] = useState(null);
  const { currentUser } = useAuth();
  const uid = currentUser.uid;

  onSnapshot(doc(db, "users", uid), orderBy("date", "desc"), (doc) => {
    const current = doc.data().firstname;
    const current2 = doc.data().lastname;
    if (JSON.stringify(firstname) !== JSON.stringify(current)) {
      setfirstname(current);
    }
    if (JSON.stringify(lastname) !== JSON.stringify(current2)) {
      setlastname(current2);
    }
  });

  return (
    <div className="account_header_container">
      <h1>
        {firstname} {lastname}
      </h1>
      <div className="account_menuItems">
        <Link to="/Account/Investing">Investing</Link>
        <Link to="/Account/Cryto">Cryto</Link>
        <Link to="/Account/Transfers">Transfers</Link>
        <Link to="/Account/Recurring">Recurring</Link>
        <Link to="/Account/Statements">Statements</Link>
        <Link to="/Account/History">History</Link>
        <Link to="/Account/Settings/PersonalInfo">Settings</Link>
        <Link to="/Account/Help">Help</Link>
      </div>
    </div>
  );
}

export default Account_Header;
