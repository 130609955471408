import React, { useEffect } from "react";

function Wa_ecuador() {
  useEffect(() => {
    window.location.href = "https://wa.link/btloik";
  }, []);

  return <div>whatsapp ecuador</div>;
}

export default Wa_ecuador;
