
              import React, { useEffect } from "react";
          import "../Article.css";

          export default function SEC({ title }) {
            useEffect(() => {
              document.title = title;
            }, []);

            return (
              <div className="article-screen">
                <div className="article-container">
                  <span className="article-secondary-text">Updated March 14, 2024 </span>
                  <h1 className="article-h1">What is the Securities and Exchange Commission (SEC)</h1>
                  <h2 className="article-h2">🤔 Understanding What is the Securities and Exchange Commission (SEC)</h2>
                  <p className="article-p1">
                  The Securities and Exchange Commission (SEC) is a government agency established in 1934 as a response to the stock market crash of 1929. Its primary purpose is to protect investors, maintain fair and efficient markets, and facilitate capital formation. The SEC achieves these goals by enforcing securities laws, issuing rules and regulations, and overseeing the operations of securities exchanges and other organizations in the financial industry.

One of the SEC's key responsibilities is to ensure that companies provide accurate and timely information to investors. This is accomplished through the enforcement of regulations such as the Securities Act of 1933 and the Securities Exchange Act of 1934. Companies that issue stocks and bonds must register with the SEC and provide regular financial disclosures to ensure transparency and prevent fraud.

Additionally, the SEC is responsible for regulating the activities of investment professionals and firms, such as brokerage firms, investment advisors, and mutual funds. This oversight helps to protect investors from fraud, conflicts of interest, and other unethical practices in the financial industry.

In summary, the Securities and Exchange Commission plays a crucial role in promoting investor confidence, maintaining the integrity of financial markets, and fostering capital formation. Its regulatory functions are essential in safeguarding the interests of investors and ensuring a level playing field for all participants in the securities industry.
                  </p>
                  <div className="article-p-example article-p1">
                    <span className="article-p-example-title">Example</span> <br />
                    The Securities and Exchange Commission (SEC) plays a vital role in overseeing the financial industry to protect investors and maintain fair and efficient markets. By enforcing securities laws and regulations, the SEC ensures that companies provide accurate information to investors and that investment professionals act ethically. Through its regulatory functions, the SEC promotes transparency, prevents fraud, and safeguards the interests of investors, ultimately contributing to the stability and integrity of the securities industry.

                  </div>
                  <h2 className="article-h2">Takeaway</h2>
                  <div className="article-blockquote">
      Investors should trust SEC regulations for transparency & protection. Follow laws to ensure fair markets.
                  </div>
                  <p className="article-p1">
                  The SEC protects investors, maintains fair markets, and ensures transparent information. Its oversight promotes ethical practices and safeguards investor interests.
                  </p>
                </div>
              </div>
            );
          }

              