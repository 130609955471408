import React from 'react'

function Account_Transfers() {
  return (
    <div>
      <h1>Transfers</h1>
    </div>
  )
}

export default Account_Transfers
