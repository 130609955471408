import React, { useEffect } from "react";
import TextHero from "../../components/TextHero/TextHero";
import hero1 from "../../img/heros/second-hero1.png";

import "./Learn.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Learn({ title }) {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, []);
  const navigate = useNavigate();
  const navigateToLibrary = () => {
    navigate("/Library");
  };
  const navigateToWiaH = () => {
    navigate("/Article/What_is_a_Hectare");
  };
  const navigateToWiaI = () => {
    navigate("/Article/What_is_an_Investment");
  };
  const navigateToWiaFTI = () => {
    navigate("/Article/What_is_a_Fixed_Term_Investment");
  };
  const navigateToGSwS = () => {
    navigate("/Article/Getting_Started_with_Shares");
  };
  const navigateToLtB = () => {
    navigate("/Article/Loaning_to_Banks");
  };
  const navigateToRM = () => {
    navigate("/Article/Risk_Management");
  };
  const navigateToOTC = () => {
    navigate("/Article/OneTime_Crops");
  };
  const navigateToLTC = () => {
    navigate("/Article/LifeTime_Crops");
  };
  const navigateToWiaP = () => {
    navigate("/Article/What_is_a_Portafolio");
  };
  const navigateToWiD = () => {
    navigate("/Article/What_is_Diversification");
  };
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <>
      <div className="learn-page">
        <div className="learn-title">
          <span>{t("learn.ib")}</span>
        </div>
        <TextHero
          img={hero1}
          bgColor="rgb(225, 235, 220)"
          title=""
          titleColor="black"
          titleExtended={t("learn.fj")}
          titleExtendedColor="black"
          description={t("learn.fjd")}
          link=""
          second
        />
      </div>
      <div className="investing-section whitebg">
        <p className="title">{t("learn.i101")}</p>
        <p className="tagline">{t("learn.i101d")}</p>

        <div className="invest-questions-section">
          <div
            className="card"
            style={{
              background:
                "url('https://images.ctfassets.net/lnmc2aao6j57/4vOyU5fQQQBR47jNsxKJ0j/3e6bd784e9d3b3f8a774e5434719918e/What_is_an_investment.svg')",
            }}
            onClick={navigateToWiaH}
          >
            <p className="card-title">{t("learn.wh")}</p>
            <p className="card-description">{t("learn.whd")}</p>
          </div>
          <div
            className="card"
            style={{
              background:
                "url('https://images.ctfassets.net/lnmc2aao6j57/WrNrmyaBTj9xZEDfOdt51/fc88232e5e5bd8c33a34536d11687b90/What_is_a_stock.svg')",
            }}
            onClick={navigateToWiaI}
          >
            <p className="card-title">{t("learn.wi")}</p>
            <p className="card-description">{t("learn.wid")}</p>
          </div>
          <div
            className="card"
            style={{
              background:
                "url('https://images.ctfassets.net/lnmc2aao6j57/tObDrvkiogKjGNhZlAggx/69c9a480d617d6fa8e27169503cbaae6/What_is_the_stock_market.svg')",
            }}
            onClick={navigateToWiaFTI}
          >
            <p className="card-title">{t("learn.wfti")}</p>
            <p className="card-description">{t("learn.wftid")}</p>
          </div>
        </div>

        <div className="articles">
          <p className="second-title">{t("learn.tmtl")}</p>
          {/* <div className="question">
            Three things to do before you start investing
          </div>
          <div className="question">
            Picking an investment: How to approach analyzing a stock
          </div>
          <div className="btns">
            <div className="btn">See Investing Articles</div>
            <div className="btn">Investor's Guild</div>
          </div> */}
        </div>
        <div className="invest-questions-section">
          <div
            className="card"
            style={{
              background:
                "url('https://images.ctfassets.net/lnmc2aao6j57/55V7lUoWmaJTeuF5nSUBsv/ca343103cd09f4bd5654ef430d6599c2/Frame_1411.svg')",
            }}
            onClick={navigateToGSwS}
          >
            <p className="card-title">{t("learn.gsws")}</p>
            <p className="card-description">{t("learn.gswsd")}</p>
          </div>
          <div
            className="card"
            style={{
              background:
                "url('https://images.ctfassets.net/lnmc2aao6j57/7hEWboIVxT6jBAPrvybnqg/797705d1d5640c18de5d452fdeb3daa5/Frame_1412.svg')",
            }}
            onClick={navigateToLtB}
          >
            <p className="card-title">{t("learn.ltb")}</p>
            <p className="card-description">{t("learn.ltbd")}</p>
          </div>
          <div
            className="card"
            style={{
              background:
                "url('https://images.ctfassets.net/lnmc2aao6j57/52QpMM51bPzc9lflKHRoqe/21998584bf1aa5d4be8558d634b2d2b8/Frame_1413.svg')",
            }}
            onClick={navigateToRM}
          >
            <p className="card-title">{t("learn.rm")}</p>
            <p className="card-description">{t("learn.rmd")}</p>
          </div>
        </div>

        {/* <div className="articles">
          <p className="second-title">There's more to learn</p>
          <div className="question">
            Three things to do before you start investing
          </div>
          <div className="question">
            Picking an investment: How to approach analyzing a stock
          </div>
          <div className="btns">
            <div className="btn">See Investing Articles</div>
            <div className="btn">Investor's Guild</div>
          </div>
        </div> */}
      </div>
      <div className="library-section">
        <div className="library-title">{t("learn.tl")}</div>
        <div className="library-tagline">{t("learn.tld")}</div>
        <div className="library-items">
          <div
            className="library-item"
            style={{
              background:
                'url("https://images.ctfassets.net/lnmc2aao6j57/2kS0Hqkc4OZYzhONyZ0rrv/047199c14f8f6e163fe0d473f6318c23/Frame_1505__2_.svg")',
              backgroundPosition: "right",
            }}
            onClick={navigateToOTC}
          >
            <h2 className="library-item-text">{t("learn.oc")}</h2>
          </div>
          <div
            className="library-item"
            style={{
              background:
                'url("https://images.ctfassets.net/lnmc2aao6j57/1gapo7VZh6qjbROt8tPGV7/d255975e301705869f5c6ef0fea6383c/Frame_1510__4_.svg")',
              backgroundPosition: "right",
            }}
            onClick={navigateToWiaP}
          >
            <h2 className="library-item-text">{t("learn.wp")}</h2>
          </div>
          <div
            className="library-item"
            style={{
              background:
                'url("https://images.ctfassets.net/lnmc2aao6j57/sJlODzRbknCcvzmypp20X/f2e6010ea32cdbd3f90099208e5aece1/Frame_1507__3_.svg")',
              backgroundPosition: "right",
            }}
            onClick={navigateToLTC}
          >
            <h2 className="library-item-text">{t("learn.lc")}</h2>
          </div>
          <div
            className="library-item"
            style={{
              background:
                'url("https://images.ctfassets.net/lnmc2aao6j57/2ddfkbCzCTwOHxjFVynZU0/61c3918094e67a67fcd0d1f024e79eb3/Frame_1506.svg")',
              backgroundPosition: "right",
            }}
            onClick={navigateToWiD}
          >
            <h2 className="library-item-text">{t("learn.wd")}</h2>
          </div>
        </div>
        <div className="btn" onClick={navigateToLibrary}>
          {t("learn.sl")}
        </div>
      </div>
    </>
  );
}
