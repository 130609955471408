import React, { useEffect } from "react";
import fti from "../../img/articles/fti.svg";
import Investment1 from "../../img/articles/investment1.svg";
import "./Article.css";

export default function Fti({ title }) {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div className="article-screen">
      <div className="article-container">
        <span className="article-secondary-text">Updated April 26, 2023</span>
        <h1 className="article-h1">What is a Fixed-Term Investment?</h1>
        <div className="article-img">
          <img src={fti} />
        </div>
        <h2 className="article-h2">🤔 Understanding a Fixed-Term Investment</h2>
        <p className="article-p1">
          A fixed-term investment is a financial arrangement where a set amount
          of money is invested for a predetermined period, earning a fixed
          interest or return. This type of investment provides stability and
          predictability, allowing investors to know exactly how much they will
          earn by the end of the term. It's a popular choice for those seeking
          to secure their funds without the fluctuations of the market.
          Fixed-term investments often come with a specified maturity date,
          giving investors the option to reassess their financial goals at the
          end of the term. This can be particularly appealing for individuals
          who value a structured approach to their financial planning. Overall,
          fixed-term investments offer a reliable way to grow your wealth while
          minimizing risk.
        </p>
        <div className="article-p-example article-p1">
          <span className="article-p-example-title">Example</span> <br />
          Investing $10,000 in a 3-year fixed-term deposit at 4% interest is an
          example of a fixed-term investment. At the end of the term, you'd
          receive $11,200, gaining $1,200 in interest while knowing your returns
          upfront.
        </div>
        <h2 className="article-h2">Takeaway</h2>
        <div className="article-blockquote">
          Stable returns over fixed durations: Fixed-term investments offer
          predictability.
        </div>
        <p className="article-p1">
          Fixed-term investments provide stability and predictability for
          investors. They involve committing a specific amount of money for a
          set period, with a known fixed interest rate. This approach is ideal
          for those seeking reliable returns without the volatility of other
          investment types. It's crucial to consider your financial goals and
          the term's duration to make the most of fixed-term investments.
        </p>
        <div className="article-img">
          {/* <img src={Investment} alt="" /> */}
        </div>
      </div>
    </div>
  );
}
