import React, { useEffect } from "react";
import "../Article.css";

export default function MKTCAP({ title }) {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div className="article-screen">
      <div className="article-container">
        <span className="article-secondary-text">Updated March 14, 2024 </span>
        <h1 className="article-h1">What is market capitalization</h1>
        <h2 className="article-h2">
          🤔 Understanding What is market capitalization
        </h2>
        <p className="article-p1">
          Market capitalization, also known as market cap, is a measure of a
          company's total value. It is calculated by multiplying the current
          share price of a company by the total number of outstanding shares. In
          simple terms, it is the total market value of a company's outstanding
          shares of stock. Market capitalization is important because it
          provides investors with an idea of a company's size and worth in
          relation to other companies. It is used to compare companies within
          the same industry or sector to determine which is the larger or more
          valuable investment opportunity. There are typically three categories
          of market capitalization: small-cap, mid-cap, and large-cap. Small-cap
          companies have a market capitalization between $300 million and $2
          billion, mid-cap companies have a market capitalization between $2
          billion and $10 billion, and large-cap companies have a market
          capitalization of $10 billion or more. It is important to note that
          market capitalization is not the same as a company's valuation or net
          worth. It represents the total value that investors are willing to pay
          for a company's stock at a specific point in time. Market
          capitalization can fluctuate based on the stock price and the number
          of outstanding shares. Investors use market capitalization as a tool
          to make investment decisions and assess the risk and potential rewards
          of investing in a particular company. It is just one of many factors
          to consider when evaluating a potential investment opportunity.
        </p>
        <div className="article-p-example article-p1">
          <span className="article-p-example-title">Example</span> <br />
          Market capitalization, or market cap, is a critical metric for
          investors to evaluate a company's value in the market. For example, if
          Company A has a market capitalization of $1 billion and Company B has
          a market capitalization of $500 million, investors may view Company A
          as a larger and potentially more stable investment. Understanding
          market cap can help investors determine the risk and potential returns
          associated with different companies, making it a valuable tool in the
          decision-making process.
        </div>
        <h2 className="article-h2">Takeaway</h2>
        <div className="article-blockquote">
          Understand market capitalization when evaluating investment
          opportunities.
        </div>
        <p className="article-p1">
          Market capitalization is a key metric for investors to gauge a
          company's value. It helps determine size, worth, and investment
          potential in comparison to other companies.
        </p>
      </div>
    </div>
  );
}
