import React, { useEffect } from "react";
import fti from "../../img/articles/fti.svg";
import Investment1 from "../../img/articles/investment1.svg";
import "./Article.css";

export default function GSwS({ title }) {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div className="article-screen">
      <div className="article-container">
        <span className="article-secondary-text">Updated April 26, 2023</span>
        <h1 className="article-h1">Get Started with Shares</h1>
        <div className="article-img">{/* <img src={fti} alt="" /> */}</div>
        <h2 className="article-h2">🤔 Understanding a Share</h2>
        <p className="article-p1">
          A share is like a piece of a pie, representing ownership in a company
          or an agricultural plantation. When you hold a share, you hold a stake
          in the assets, profits, and growth of the entity. It's a way to
          participate in the success of the company or the productivity of the
          plantation. Shares can be bought and sold in financial markets,
          allowing investors to diversify their portfolios and potentially earn
          returns as the company or plantation thrives. Whether it's tech
          innovation or agricultural abundance, owning a share means being a
          part of something bigger and sharing in its prosperity.
        </p>
        <div className="article-p-example article-p1">
          <span className="article-p-example-title">Example</span> <br />A share
          of an hectare in a tree plantation is akin to owning a portion of a
          thriving forest venture. When you invest in such a share, you become a
          co-owner of the land and its trees. As the trees mature and yield
          timber, you reap a portion of the profits. This type of investment
          offers a sustainable and environmentally conscious way to potentially
          grow your wealth while contributing to reforestation and ecological
          balance. Just like a share in a company, a share in a tree plantation
          lets you be a part of nurturing nature and sharing in its rewards.
        </div>
        <h2 className="article-h2">Takeaway</h2>
        <div className="article-blockquote">
          Shares give you the ability to Invest in lots of Ventures without a
          Large Capital
        </div>
        <p className="article-p1">
          Shares provide a gateway to invest in pricier ventures with limited
          capital. By owning a fraction of high-value assets, like companies or
          plantations, you access opportunities that might otherwise be
          financially out of reach. This way, you can diversify your investments
          and potentially enjoy substantial returns without the need for
          substantial upfront funds.
        </p>
        <div className="article-img">
          {/* <img src={Investment} alt="" /> */}
        </div>
      </div>
    </div>
  );
}
