import React, { useEffect } from "react";
import wiap from "../../img/articles/WiaP.svg";
import Investment1 from "../../img/articles/investment1.svg";
import "./Article.css";

export default function WiaP({ title }) {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div className="article-screen">
      <div className="article-container">
        <span className="article-secondary-text">Updated April 26, 2023</span>
        <h1 className="article-h1">What is a Portafolio?</h1>
        <div className="article-img">
          <img src={wiap} alt="" />
        </div>
        <h2 className="article-h2">🤔 Understanding a Portafolio</h2>
        <p className="article-p1">
          A financial portfolio refers to a collection of investments held by an
          individual or an entity, such as stocks, bonds, mutual funds, real
          estate, and other assets. The purpose of building a portfolio is to
          diversify risk and potentially achieve financial goals.
          Diversification helps spread risk across various assets, reducing the
          impact of poor performance in any single investment. Portfolios are
          usually tailored to an individual's risk tolerance, time horizon, and
          financial objectives. Regular monitoring and adjustments are necessary
          to ensure the portfolio remains aligned with changing goals and market
          conditions. The goal is to achieve a balance between potential returns
          and acceptable levels of risk based on the investor's unique
          circumstances.
        </p>
        <div className="article-p-example article-p1">
          <span className="article-p-example-title">Example</span>
          <br />
          Meet James, a 29-year-old professional with a keen interest in
          building a solid financial future. He recently came into $100,000, and
          he's determined to invest it wisely. James understands the importance
          of diversification to manage risk, given his age and financial goals.
          <br />
          James decides to divide his $100,000 into different investment
          avenues:
          <h4>Agricultural Funds:</h4> James allocates $30,000 to agricultural
          funds. He believes in the long-term potential of sustainable
          agriculture and expects steady growth in this sector.
          <h4>Banking Funds:</h4> To balance his portfolio, James invests
          $25,000 in banking funds. He considers this a relatively stable option
          that can provide consistent returns over time.
          <h4>Cash:</h4> Recognizing the importance of liquidity, James keeps
          $15,000 in cash. This provides him with a financial cushion and the
          flexibility to seize opportunities as they arise.
          <h4>Stocks:</h4> James is willing to take some calculated risks, so he
          invests $6,000 in individual stocks of high-growth companies operating
          in emerging industries. He's well aware that stock investments can be
          volatile, but he's drawn to their potential for substantial returns.
          <br />
          <br />
          James believes that a diversified approach will help him manage risk
          while pursuing growth. He plans to monitor his investments regularly
          and make adjustments as needed, aligning his portfolio with his
          evolving financial goals.
        </div>
        <h2 className="article-h2">Takeaway</h2>
        {/* <div className="article-blockquote">
          Shares give you the ability to Invest in lots of Ventures without a
          Large Capital
        </div> */}
        <p className="article-p1">
          A portfolio is a mix of investments like stocks and bonds held by an
          individual or entity, aimed at achieving financial goals through
          diversification and prudent management.
        </p>
        <div className="article-img">
          {/* <img src={Investment} alt="" /> */}
        </div>
      </div>
    </div>
  );
}
