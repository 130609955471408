import React, { useEffect } from "react";
import Hero from "../../components/Hero/Hero";
import "./Landing.css";

import hero1 from "../../img/heros/hero1.png";
import hero2 from "../../img/heros/hero2.png";
import hero3 from "../../img/heros/hero3.png";

import investorImg from "../../img/investor.png";

import tag1 from "../../img/tag1.png";
import tag2 from "../../img/tag2.png";
import tag3 from "../../img/tag3.png";
import tag4 from "../../img/tag4.png";

import preFooterBg from "../../img/pre-footer-bg.png";

import TextHero from "../../components/TextHero/TextHero";
import { useTranslation } from "react-i18next";

export default function Landing({ title }) {
  let output = [];
  let pattern = "100";
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, []);

  // const numbers = inputData.map(Number)
  //   if (pattern.test(numbers)) {

  //   }
  //   if (numbers.length !== 0) {
  //       numbers.replace(pattern,"")

  // }else {
  // output.push("yes")
  // }

  useEffect(() => {
    document.title = title;
  }, []);
  return (
    <div className="landing_container">
      <Hero />
      <div className="latest">
        <div className="latest-container">
          <span className="latest-title">{t("landing.cool")}</span>
          {/* <div className="article-list">
            <div className="article"></div>
          </div> */}
        </div>
      </div>
      <TextHero
        img={hero1}
        bgColor="rgb(195, 245, 60)"
        title={t("landing.i")}
        titleColor="rgb(0, 200, 5)"
        titleExtended={t("landing.byp")}
        titleExtendedColor="black"
        description={t("landing.iia")}
        link=""
      />
      <TextHero
        img={hero2}
        bgColor="rgb(24, 17, 45)"
        title={t("landing.bf")}
        titleColor="rgb(255, 90, 135)"
        titleExtended={t("landing.dri")}
        titleExtendedColor="white"
        description={t("landing.ghr")}
        link=""
      />
      <TextHero
        img={hero3}
        bgColor="rgb(0, 44, 21)"
        title={t("landing.per")}
        titleColor="rgb(195, 245, 60)"
        titleExtended={t("landing.hr")}
        titleExtendedColor="white"
        description={t("landing.lf")}
        link=""
      />
      <div className="guarantee-section">
        <span className="section-title2">{t("landing.afp")}</span>
        <div className="items">
          <div className="item">
            <div className="img">
              <img src={tag1} className="img-tag" />
            </div>
            <span className="text">{t("landing.wwh")}</span>
          </div>
          <div className="item">
            <div className="img">
              <img src={tag4} className="img-tag" />
            </div>
            <span className="text">{t("landing.wpya")} </span>
          </div>
          <div className="item">
            <div className="img">
              <img src={tag2} className="img-tag" />
            </div>
            <span className="text">{t("landing.wpmfa")} </span>
          </div>
          <div className="item">
            <div className="img">
              <img src={tag3} className="img-tag" />
            </div>
            <span className="text">{t("landing.wgyb")} </span>
          </div>
        </div>
      </div>
      <div className="investor-section">
        <span className="section-title">{t("landing.bbi")}</span>
        <p>{t("landing.hiap")}</p>
        <img className="investor-img" src={investorImg} />
      </div>
      <div className="image-container">
        <img
          src={preFooterBg}
          alt="Pre-footer image"
          className="pre-footer-section"
        />
        <div className="text-overlay">
          <span className="section-title">{t("landing.ngi")} </span>
        </div>
      </div>
    </div>
  );
}
