import React from 'react'
import "./Reward_Description.css"

function Reward_Description() {
  return (
    <div className='reward_description'>
      <div className='reward_description_container'>
        <h1>
        Invite friends. Pick your free piece of stock!
Get up to $200 in stock for every referral! Limitations apply.
        </h1>


      </div>
    </div>
  )
}

export default Reward_Description
