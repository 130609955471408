import React, { useEffect } from "react";
import ltc from "../../img/articles/LTC.svg";
import Investment1 from "../../img/articles/investment1.svg";
import "./Article.css";

export default function LTC({ title }) {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div className="article-screen">
      <div className="article-container">
        <span className="article-secondary-text">Updated April 26, 2023</span>
        <h1 className="article-h1">LifeTime Crops</h1>
        <div className="article-img">
          <img src={ltc} alt="" />
        </div>
        <h2 className="article-h2">🤔 Understanding a LifeTime Crop</h2>
        <p className="article-p1">
          Otherwise called "Perennial Crops" <br />
          These crops are intentionally planted and nurtured to ensure
          consistent and sustainable production over an extended period. They
          develop deep root systems and often require less frequent replanting
          and less intensive cultivation than annual crops. This characteristic
          allows them to endure various environmental conditions and continue
          yielding produce year after year without the need for replanting.
        </p>
        <div className="article-p-example article-p1">
          <span className="article-p-example-title">Example</span> <br />
          Avocado Trees can anywhere from 5 to 13 years or more to produce
          fruit,grafted trees tend to produce fruit much faster within 2 to 4
          years under optimal conditions.
          <br />
          Cocoa Trees take 4 years to grow before they start producing cocoa
          beens
        </div>
        <h2 className="article-h2">Takeaway</h2>
        {/* <div className="article-blockquote">
          Shares give you the ability to Invest in lots of Ventures without a
          Large Capital
        </div> */}
        <p className="article-p1">
          🌱 "Lifetime Crops, or Perennial Crops, are like nature's gift for
          continuous harvests. With deep roots and less replanting, they thrive
          in different conditions, giving sustained produce without yearly
          restarts." 🌾
        </p>
        <div className="article-img">
          {/* <img src={Investment} alt="" /> */}
        </div>
      </div>
    </div>
  );
}
