import React, { useEffect } from "react";
import Investment from "../../img/articles/investment.svg";
import Investment1 from "../../img/articles/investment1.svg";
import "./Article.css";

export default function InvestmentArticle({ title }) {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div className="article-screen">
      <div className="article-container">
        <span className="article-secondary-text">Updated April 26, 2023</span>
        <h1 className="article-h1">What is an Investment?</h1>
        <div className="article-img">
          <img src={Investment1} alt="" />
        </div>
        <h2 className="article-h2">🤔 Understanding an Investment</h2>
        <p className="article-p1">
          An investment is an asset bought by an individual or organization with
          the expectation that it will generate some future income or profit.
          It's a strategic commitment of funds into various financial
          instruments, such as stocks, bonds, real estate, or mutual funds,
          aimed at increasing wealth over time. Investors carefully analyze
          potential risks and rewards before allocating resources, considering
          factors like market trends, economic indicators, and company
          performance. The goal is to achieve capital appreciation, passive
          income, or both, while managing potential fluctuations in value.
          Diversification and a long-term perspective are often employed to
          balance risk and optimize returns. Successful investing requires a mix
          of research, prudent decision-making, and adaptability to
          ever-changing market conditions.
        </p>
        <div className="article-p-example article-p1">
          <span className="article-p-example-title">Example</span> <br />
          An example of an investment is when an individual or business provides
          a fixed-term loan to a bank. In return, they receive a predetermined
          interest rate over the loan period. This allows the investor to earn a
          predictable return on their funds while the bank utilizes the loan for
          its operations. The investment is secured by the terms of the loan
          agreement and the financial stability of the bank.
        </div>
        <h2 className="article-h2">Takeaway</h2>
        <div className="article-blockquote">
          An investment is like planting a seed...
        </div>
        <p className="article-p1">
          If you put a seed in the correct place at the correct time, and take
          care of it properly, eventually that seed might turn into something
          that continues to provide. Imagine a tree that gives lemons, a plant
          that produces strawberries, or a flower that smells nice. Similarly,
          when you put money into something hoping it will increase, it could
          end up being worth more than what you initially spent. However, like a
          plant can die, there's a chance that you could lose money with an
          investment.
        </p>
        <div className="article-img">
          <img src={Investment} alt="" />
        </div>
      </div>
    </div>
  );
}
