import React from "react";
import { Route, Switch } from "react-router-dom";
import Footer from "./Outside App/components/Footer/Footer";

import TLH from "./Outside App/pages/Articles/AI/TLH";
import ROE from "./Outside App/pages/Articles/AI/ROE";
import WIB from "./Outside App/pages/Articles/AI/WIB";
import NI from "./Outside App/pages/Articles/AI/NI";
import DRIP from "./Outside App/pages/Articles/AI/DRIP";
import Ii from "./Outside App/pages/Articles/AI/Ii";
import SSP from "./Outside App/pages/Articles/AI/SSP";
import WIMI from "./Outside App/pages/Articles/AI/WIMI";
import DI from "./Outside App/pages/Articles/AI/DI";
import WI from "./Outside App/pages/Articles/AI/WI";
import WIC from "./Outside App/pages/Articles/AI/WIC";
import MSN from "./Outside App/pages/Articles/AI/MSN";
import WIDJI from "./Outside App/pages/Articles/AI/WIDJI";
import SPX from "./Outside App/pages/Articles/AI/SPX";
import MKTCAP from "./Outside App/pages/Articles/AI/MKT CAP";
import BBM from "./Outside App/pages/Articles/AI/BBM";
import SEC from "./Outside App/pages/Articles/AI/SEC";
import NQA from "./Outside App/pages/Articles/AI/NQA";
import NYSE from "./Outside App/pages/Articles/AI/NYSE";
import FV from "./Outside App/pages/Articles/AI/FV";
import CEO from "./Outside App/pages/Articles/AI/CEO";
import CL from "./Outside App/pages/Articles/AI/CL";
import WIL from "./Outside App/pages/Articles/AI/WIL";
import CFS from "./Outside App/pages/Articles/AI/CFS";
export default [
  <Route
    path="/Article/What_is_a_cash_flow_statement"
    element={
      <>
        <CFS title="What is a cash flow statement" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_leverage"
    element={
      <>
        <WIL title="What is leverage" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_are_current_liabilities"
    element={
      <>
        <CL title="What are current liabilities" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_a_Chief_Executive_Officer_(CEO)"
    element={
      <>
        <CEO title="What is a Chief Executive Officer (CEO)" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_future_value_(FV)"
    element={
      <>
        <FV title="What is future value (FV)" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_the_New_York_Stock_Exchange_(NYSE)"
    element={
      <>
        <NYSE title="What is the New York Stock Exchange (NYSE)" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_the_NASDAQ"
    element={
      <>
        <NQA title="What is the NASDAQ" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_the_Securities_and_Exchange_Commission_(SEC)"
    element={
      <>
        <SEC title="What is the Securities and Exchange Commission (SEC)" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_are_bull_and_bear_markets"
    element={
      <>
        <BBM title="What are bull and bear markets" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_market_capitalization"
    element={
      <>
        <MKTCAP title="What is market capitalization" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_the_S&P_500"
    element={
      <>
        <SPX title="What is the S&P 500" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_the_Dow"
    element={
      <>
        <WIDJI title="What is the Dow" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_money_supply"
    element={
      <>
        <MSN title="What is money supply" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_collusion"
    element={
      <>
        <WIC title="What is collusion" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_infrastructure"
    element={
      <>
        <WI title="What is infrastructure" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_disability_insurance"
    element={
      <>
        <DI title="What is disability insurance" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_margin"
    element={
      <>
        <WIMI title="What is margin" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_a_short_sale_property"
    element={
      <>
        <SSP title="What is a short sale property" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_interest"
    element={
      <>
        <Ii title="What is interest" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_a_dividend_reinvestment_plan_(DRIP)"
    element={
      <>
        <DRIP title="What is a dividend reinvestment plan (DRIP)" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_net_income"
    element={
      <>
        <NI title="What is net income" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_beta"
    element={
      <>
        <WIB title="What is beta" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_return_on_equity"
    element={
      <>
        <ROE title="What is return on equity" />
        <Footer />
      </>
    }
  />,

  <Route
    path="/Article/What_is_tax_loss_harvesting"
    element={
      <>
        <TLH title="What is tax loss harvesting" />
        <Footer />
      </>
    }
  />,
];
