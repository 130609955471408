import React, { useEffect } from "react";
import Hectare from "../../img/articles/hectare.svg";
import "./Article.css";

export default function HectareArticle({ title }) {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div className="article-screen">
      <div className="article-container">
        <span className="article-secondary-text">Updated April 26, 2023</span>
        <h1 className="article-h1">What is a Hectare?</h1>
        <div className="article-img">
          <img src={Hectare} alt="" />
        </div>
        <h2 className="article-h2">🤔 Understanding a Hectare</h2>
        <p className="article-p1">
          10,000 square meters of land, equivalent to 2.47 acres, dedicated to
          the cultivation of a specific crop or trees constitutes a hectare
          plantation. A hectare, a metric unit of area, provides a substantial
          canvas for agricultural endeavors. It's a fundamental measure in
          agriculture, allowing farmers to efficiently plan and manage their
          cultivation efforts. With its well-defined size, a hectare offers a
          versatile space that balances yield and resource optimization,
          contributing to sustainable and productive farming practices
          worldwide.
        </p>
        <div className="article-p-example article-p1">
          <span className="article-p-example-title">Example</span> <br />
          Imagine a lush piece of farmland spanning 10,000 square meters, which
          is equivalent to one hectare. To help visualize its dimensions,
          picture a perfect square plot with each side measuring 100 meters.
          This means the length of one side of the square is the same as the
          length of a football field. Now, within this expanse, various
          agricultural wonders can unfold. From neatly aligned rows of thriving
          crops to a serene orchard of fruit-bearing trees, the possibilities
          are expansive. This 10,000-square-meter canvas offers ample room for
          precision planting, efficient irrigation, and optimized sunlight
          exposure. Whether it's a bountiful vegetable garden or a vibrant
          vineyard, this hectare-sized plot represents a versatile platform for
          nurturing nature's bounty and sustaining agricultural prosperity.
        </div>
        <h2 className="article-h2">Takeaway</h2>
        <div className="article-blockquote">
          Hectare is a mesuring tool farmers use to size their Plantations..
        </div>
        <p className="article-p1">
          A hectare encompasses 10,000 sq. meters, a space akin to a 100m x 100m
          square. This ample area fosters diverse agricultural pursuits,
          enabling efficient cultivation and yielding abundant harvests.
        </p>
        <div className="article-img">
          {/* <img
            src="https://images.ctfassets.net/lnmc2aao6j57/4AUsvo2qjpwlcdBNzFK6hi/376389bf1b185964ac526c762c8bb579/EPS_illustration-15.svg"
            alt=""
          /> */}
        </div>
      </div>
    </div>
  );
}
