import React, { useEffect } from "react";
import otc from "../../img/articles/OTC.svg";
import Investment1 from "../../img/articles/investment1.svg";
import "./Article.css";

export default function OTC({ title }) {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div className="article-screen">
      <div className="article-container">
        <span className="article-secondary-text">Updated April 26, 2023</span>
        <h1 className="article-h1">OneTime Crops</h1>
        <div className="article-img">
          <img src={otc} alt="" />
        </div>
        <h2 className="article-h2">🤔 Understanding a OneTime Crop</h2>
        <p className="article-p1">
          These type of Crops are sown, grown, harvested, and then the entire
          life cycle of the plant comes to an end.
          <br />
          <br />
          During their growth period, these crops require care and maintenance
          to ensure optimal conditions for growth, including proper soil
          preparation, irrigation, fertilization, and protection from pests and
          diseases. As they mature, they eventually reach a stage where their
          produce is ready for harvest. Once harvested, the plant no longer
          continues to grow, and its life cycle comes to a close.
          <br />
          <br />
          It's worth noting that after harvest, some parts of the plant, such as
          the remaining plant residues, might still have uses. For example, in
          certain agricultural practices, leftover plant material might be
          plowed back into the soil to enrich its nutrient content or serve as
          organic matter.
        </p>
        <div className="article-p-example article-p1">
          <span className="article-p-example-title">Example</span> <br />
          Corn is planted and when the corn its picked up the rest of the plant
          its useless.
          <br /> Balsa its a Tree that its growned for its wood.
          <br />
          Pineapple only gives 1 fruit per plant, and gives seedlyings and after
          the plant is no longer usefull.
        </div>
        <h2 className="article-h2">Takeaway</h2>
        {/* <div className="article-blockquote">
          Shares give you the ability to Invest in lots of Ventures without a
          Large Capital
        </div> */}
        <p className="article-p1">
          In conclusion, these types of plantations are like a piggy bank they
          take some time to fill up and when you break them, you get all the
          benefit at one, but you dont have a piggy bank anymore.
        </p>
        <div className="article-img">
          {/* <img src={Investment} alt="" /> */}
        </div>
      </div>
    </div>
  );
}
