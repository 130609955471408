import React from 'react'
import "./Reward_Referal.css"


function Reward_Referral() {
  return (
    <div className='reward_referral'>
        <div className='reward_referral_container'>
            
        </div>
    </div>
  )
}

export default Reward_Referral
