import React, { useEffect } from "react";
// import fti from "../../img/articles/fti.svg";
// import Investment1 from "../../img/articles/investment1.svg";
import "./Article.css";

export default function LtB({ title }) {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <div className="article-screen">
      <div className="article-container">
        <span className="article-secondary-text">Updated April 26, 2023</span>
        <h1 className="article-h1">Loaning to Banks</h1>
        <div className="article-img">{/* <img src={fti} alt="" /> */}</div>
        <h2 className="article-h2">🤔 Understanding Risk Management</h2>
        <p className="article-p1">
          "Loaning to a bank involves lending a certain amount of money to the
          bank with the expectation of receiving a predetermined percentage of
          return, often in the form of interest. This arrangement allows the
          bank to utilize the borrowed funds for various purposes, such as
          lending to other individuals or businesses, investing in financial
          markets, or funding its operations. In return, the lender, or
          investor, benefits from a regular income stream in the form of
          interest payments. While generally considered a low-risk investment
          due to the bank's stability, potential returns may vary based on the
          interest rate agreed upon and the terms of the loan. It's important to
          assess the bank's financial health, interest rates, and the overall
          market conditions before entering into such an arrangement."
        </p>
        <div className="article-p-example article-p1">
          <span className="article-p-example-title">Example</span> <br />
          Loaning $10,000 to a bank means providing them with funds they can use
          for various purposes, like lending to others or investments. In return
          for your loan, the bank agrees to pay you interest, say 3%. This means
          you could earn $300 annually, benefiting from a reliable return on
          your investment.
        </div>
        <h2 className="article-h2">Takeaway</h2>
        <div className="article-blockquote">
          Loaning to a Bank its one of the most secure thing you can invest in..
        </div>
        <p className="article-p1">
          Loaning to a bank means lending money for a set return. It's a stable
          investment, providing regular interest payments based on agreed terms.
        </p>
        <div className="article-img">
          {/* <img src={Investment} alt="" /> */}
        </div>
      </div>
    </div>
  );
}
